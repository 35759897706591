import { useState, useEffect } from 'react';


function TickingClock() {

  const [currentTime, setTime] = useState(new Date().toLocaleTimeString());

  useEffect(() => {
    setInterval(() => {
      setTime(new Date().toLocaleTimeString());
    }, 100);
  }, [currentTime])

  return (
      currentTime
  );
}
function App() {
  return (
    <>
      <TickingClock />
    </>
  );
}

export default App;
